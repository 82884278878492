import React from 'react';
import { AuthenticatedRoute } from '../../components/app';

function getMobileOperatingSystem() {
  if (typeof navigator === 'undefined' || typeof window === 'undefined') {
    return;
  }
  // @ts-ignore
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Android detection. Windows phones also have "Android" in the user agent so
  // making sure windows isn't coupled with Android to avoid Windows phones
  if (!/windows phone/i.test(userAgent) && /android/i.test(userAgent)) {
    window.location.href =
      'https://play.google.com/store/apps/details?id=com.amarbleapp.marble&hl=en';
  }
  // iOS detection
  // @ts-ignore
  else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    window.location.href =
      'https://apps.apple.com/us/app/marble-renting-made-easy/id1221104767';
  }
}

export default function App(): JSX.Element {
  getMobileOperatingSystem();

  return <AuthenticatedRoute />;
}
